import React from "react"
import { Link } from "react-router-dom"
import { Counter } from "../layouts/about"
import BannerTitle from "../layouts/about/banner/BannerTitle"
import { About, Faq, IconBox, Overview, TitleHeading } from "../layouts/home01/index"
import { BottomBar, Header, TopBar } from "../layouts/layout/index"
import { FaWhatsapp } from "react-icons/fa"
import ReactWhatsapp from "react-whatsapp"
import { Helmet } from "react-helmet"

const AboutUs = () => {
  const state = {
    headers: [
      {
        id: 1,
        logoweb: "assets/img/logo-small.png",
        names: "Edilfast",
      },
    ],
    headingchoose: [
      {
        id: 1,
        classnames: "heading",
        title: "Fai la scelta migliore",
        text: "Migliaia di lavori completati nel milanese e dintorni - Con più di 30 anni di esperienza nel campo.",
        classtext: "sub-heading",
      },
    ],
    banners: [
      {
        id: 1,
        links: "",
        titlelink: "",
        border: "",
        name: "EdilFast",
        heading: "la nostra impresa",
      },
    ],
  }

  return (
    <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      <Helmet>
        <title>Edil fast - Impresa Edile Milano - Ristrutturazioni Milano</title>
        <link rel="canonical" href="https://galloedilfast.it/su-di-noi" />
        <meta
          name="description"
          content="Impresa edile milano , Migliaia di lavori completati nel milanese e dintorni - Con più di 30 anni di esperienza nel campo."
        />
        <meta
          name="keywords"
          content="Impresa edile,ristrutturazione,ristrutturazione bagni,piastrellature,marmi,velature,idraulici,imbiancature"
        />
      </Helmet>
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            <TopBar />
            {state.headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          {state.banners.map((data) => (
            <BannerTitle key={data.id} data={data} />
          ))}
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-iconbox">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={60}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            {state.headingchoose.map((data) => (
                              <TitleHeading data={data} key={data.id} />
                            ))}
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={42}
                              data-mobile={35}
                              data-smobile={35}
                            />
                          </div>
                        </div>

                        <IconBox />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="themesflat-spacer clearfix"
                          data-desktop={58}
                          data-mobile={35}
                          data-smobile={35}
                        />
                      </div>
                    </div>
                    <About />
                    <Counter />
                    <div className="row-tabs" style={{ marginBottom: "100px" }}>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={61}
                              data-mobile={60}
                              data-smobile={60}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <Overview />
                          <Faq />
                        </div>
                      </div>
                    </div>
                    <div className="row-quote bg-row-1">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={40}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            <div className="themesflat-quote clearfix">
                              <div className="quote-item">
                                <div className="inner">
                                  <div className="heading-wrap">
                                    <h3 className="heading">
                                      Inizia il tuo lavoro ora con EdilFast
                                    </h3>
                                  </div>
                                  <div className="button-wrap has-icon icon-left">
                                    <Link
                                      to="#"
                                      className="themesflat-button  small"
                                    >
                                      <ReactWhatsapp
                                        number="+393288362365"
                                        message=""
                                      >
                                        <FaWhatsapp />
                                      </ReactWhatsapp>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={31}
                              data-mobile={60}
                              data-smobile={60}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {state.headers.map((data, idx) => (
            <BottomBar data={data} key={idx} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AboutUs
