import React from "react"

const Counter = () => {
  return (
    <div className="row-counter parallax parallax-4 parallax-overlay">
      <div className="container">
        <div className="row clearfix">
          <div className="col-md-12">
            <div
              className="themesflat-spacer clearfix"
              data-desktop={92}
              data-mobile={60}
              data-smobile={60}
            />
          </div>
        </div>
        <div className="themesflat-row gutter-30 separator light clearfix">
          <div className="col span_1_of_4">
            <div
              className="themesflat-content-box clearfix"
              data-margin="0 0 0 0"
              data-mobilemargin="0 0 0 0"
            >
              <div className="themesflat-counter style-1 align-center clearfix">
                <div className="counter-item">
                  <div className="inner">
                    <div className="text-wrap">
                      <div className="number-wrap">
                        <span
                          className="number"
                          data-speed={2000}
                          data-to={1000}
                          data-inviewport="yes"
                        >
                          1000
                        </span>
                        <span className="suffix">+</span>
                      </div>
                      <h3 className="heading margin-right-18">Lavori completati</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="themesflat-spacer clearfix"
              data-desktop={0}
              data-mobile={35}
              data-smobile={35}
            />
          </div>
          <div className="col span_1_of_4">
            <div
              className="themesflat-content-box clearfix"
              data-margin="0 0 0 0"
              data-mobilemargin="0 0 0 0"
            >
              <div className="themesflat-counter style-1 align-center clearfix">
                <div className="counter-item">
                  <div className="inner">
                    <div className="text-wrap">
                      <div className="number-wrap">
                        <span
                          className="number"
                          data-speed={2000}
                          data-to={100}
                          data-inviewport="yes"
                        >
                          100
                        </span>
                        <span className="suffix">+</span>
                      </div>
                      <h3 className="heading margin-right-8">Clienti soddisfatti</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="themesflat-spacer clearfix"
              data-desktop={0}
              data-mobile={0}
              data-smobile={35}
            />
          </div>
          <div className="col span_1_of_4">
            <div
              className="themesflat-content-box clearfix"
              data-margin="0 0 0 0"
              data-mobilemargin="0 0 0 0"
            >
              <div className="themesflat-counter style-1 align-center clearfix">
                <div className="counter-item">
                  <div className="inner">
                    <div className="text-wrap">
                      <div className="number-wrap">
                        <span
                          className="number"
                          data-speed={2000}
                          data-to={20}
                          data-inviewport="yes"
                        >
                          20
                        </span>
                        <span className="suffix">+</span>
                      </div>
                      <h3 className="heading margin-right-10">
                        Specialisti muratori , imbianchini , idraulici e
                        piastrellisti{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-md-12">
            <div
              className="themesflat-spacer clearfix"
              data-desktop={72}
              data-mobile={60}
              data-smobile={60}
            />
          </div>
        </div>
      </div>
      <div className="bg-parallax-overlay overlay-black style2" />
    </div>
  )
}

export default Counter
