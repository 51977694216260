import React from "react"

const Overview = () => {
  const state = {
    tab: [
      {
        id: 1,
        text: "Siamo pronti per iniziare il tuo lavoro",
        item: [
          {
            idx: 1,
            content: "Contattaci e spiegaci di cosa hai bisogno.",
          },
          {
            idx: 2,
            content: "Riceverai presto un preventivo.",
          },
          {
            idx: 3,
            content:
              "Se sarai soddisfatto EdilFast sarà pronto per iniziare il tuo lavoro",
          },
        ],
      },
      {
        id: 2,
        text: "Il vostro lavoro è importante per noi",
        item: [
          {
            idx: 1,
            content: "Dedizione al lavoro",
          },
          {
            idx: 2,
            content: "Costanza al lavoro",
          },
          {
            idx: 3,
            content: "Raggiungimento degli obbiettivi",
          },
        ],
      },
      {
        id: 3,
        text: "Risultati eccellenti su misura",
        item: [
          {
            idx: 1,
            content: "Una volta che sappiamo cosa fare lo facciamo e bene",
          },
        ],
      },
    ],
  }

  return (
    <div className="col-md-6">
      <div
        className="themesflat-content-box clearfix"
        data-margin="0 18px 0 0"
        data-mobilemargin="0 0 0 0"
      >
        <div className="themesflat-headings style-1 clearfix">
          <h2 className="heading">OVERVIEW</h2>
          <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
        </div>
        <div
          className="themesflat-spacer clearfix"
          data-desktop={38}
          data-mobile={35}
          data-smobile={35}
        />
        <div className="themesflat-tabs style-1 clearfix">
          <ul className="tab-title clearfix">
            <li className="item-title active">
              <span className="inner">Obbiettivi</span>
            </li>
            <li className="item-title">
              <span className="inner">Lavoro</span>
            </li>
            <li className="item-title">
              <span className="inner">Risultati</span>
            </li>
          </ul>
          <div className="tab-content-wrap clearfix">
            {state.tab.map((data) => (
              <div className="tab-content" key={data.id}>
                <div className="item-content">
                  <p>{data.text}</p>
                  {data.item.map((index) => (
                    <div
                      className="themesflat-list has-icon style-1 icon-left ofset-padding-32 clearfix"
                      key={index.idx}
                    >
                      <div className="inner">
                        <span className="item">
                          <span className="icon">
                            <i className="fa fa-check-square" />
                          </span>
                          <span className="text">{index.content}</span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {/* /.tab-content-wrap */}
        </div>
        {/* /.themesflat-tabs */}
      </div>
      {/* /.themesflat-content-box */}
    </div>
  )
}

export default Overview
