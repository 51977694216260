import React from "react"
import { Link } from "react-router-dom"
import ReactWhatsapp from "react-whatsapp"
import { FaWhatsapp } from "react-icons/fa"

const Footer = () => {
  return (
    <div>
      <p className="whatsapp_float">
        <ReactWhatsapp number="+393288362365" message="">
          <FaWhatsapp />
        </ReactWhatsapp>
      </p>
      <footer id="footer" className="clearfix">
        <div id="footer-widgets" className="container">
          <div className="themesflat-row gutter-30">
            <div className="col span_1_of_3">
              <div className="widget widget_text">
                <div className="textwidget">
                  <p>
                    <img
                      src={window.location.origin + "/assets/img/logo-small.png"}
                      alt="EdilFast"
                      width={186}
                      height={39}
                      data-retina={"/assets/img/logo-small.png"}
                      data-width={186}
                      data-height={39}
                    />
                  </p>
                  <p className="margin-bottom-15">
                    Abbiamo più di 30 anni di esperienza affidati ad esperti.
                  </p>
                </div>
              </div>
              {/* /.widget_text */}
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={0}
                data-smobile={35}
              />
            </div>
            {/* /.col */}
            <div className="col span_1_of_3">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={35}
                data-smobile={35}
              />
              <div className="widget widget_tags">
                <h2 className="widget-title margin-bottom-30">
                  <span>SERVIZI</span>
                </h2>
                <div className="tags-list">
                  <Link to="#">Rifacimento bagni e cucine</Link>
                  <Link to="#">Piastrellature - Mosaici</Link>
                  <Link to="#">Marmi e piastrelle</Link>
                  <Link to="#">Pareti in cartongesso</Link>
                  <Link to="#">Imbiancatura - Stucchi veneziani</Link>
                  <Link to="#">Velature - Tamponanti</Link>
                </div>
              </div>
            </div>
            {/* <div className="col span_1_of_3">
                                <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={35} data-smobile={35} />
                                <div className="widget widget_instagram">
                                    <h2 className="widget-title margin-bottom-30"><span>INSTAGRAM PHOTOS</span></h2>
                                    <div className="instagram-wrap data-effect clearfix col3 g10">
                                        <div className="instagram_badge_image has-effect-icon">
                                            <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="assets/img/instagram/instagram-1-83x83.jpg"
                                                                        alt="imagealt" /></span>
                                                <div className="overlay-effect bg-color-2" />
                                                <div className="elm-link">
                                                    <span className="icon-3" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="instagram_badge_image has-effect-icon">
                                            <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="assets/img/instagram/instagram-2-83x83.jpg"
                                                                        alt="imagealt" /></span>
                                                <div className="overlay-effect bg-color-2" />
                                                <div className="elm-link">
                                                    <span className="icon-3" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="instagram_badge_image has-effect-icon">
                                            <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="assets/img/instagram/instagram-3-83x83.jpg"
                                                                        alt="imagealt" /></span>
                                                <div className="overlay-effect bg-color-2" />
                                                <div className="elm-link">
                                                    <span className="icon-3" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="instagram_badge_image has-effect-icon">
                                            <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="assets/img/instagram/instagram-4-83x83.jpg"
                                                                        alt="imagealt" /></span>
                                                <div className="overlay-effect bg-color-2" />
                                                <div className="elm-link">
                                                    <span className="icon-3" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="instagram_badge_image has-effect-icon">
                                            <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="assets/img/instagram/instagram-5-83x83.jpg"
                                                                        alt="imagealt" /></span>
                                                <div className="overlay-effect bg-color-2" />
                                                <div className="elm-link">
                                                    <span className="icon-3" />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="instagram_badge_image has-effect-icon">
                                            <Link to="#" target="_blank" className="data-effect-item">
                                            <span className="item"><img src="assets/img/instagram/instagram-6-83x83.jpg"
                                                                        alt="imagealt" /></span>
                                                <div className="overlay-effect bg-color-2" />
                                                <div className="elm-link">
                                                    <span className="icon-3" />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            <div className="col span_1_of_3">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={35}
                data-smobile={35}
              />
              <ul>
                <li>
                  <div className="inner">
                    <span className="fa fa-map-marker" />
                    <span className="text">
                      Via bolivian 20 <span className="sl"> Pioltello (mi) </span>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="inner">
                    <span className="text">P. iva: 13480430969</span>
                  </div>
                </li>
                <li>
                  <div className="inner">
                    <span className="fa fa-phone" />
                    <span className="text">
                      Chiama , Preventivi gratuiti senza impegno! : (+39) 3288362365
                    </span>
                  </div>
                </li>
                <li className="margin-top-7">
                  <div className="inner">
                    <span className=" font-size-14 fa fa-envelope" />
                    <a
                      className="text-danger"
                      href="mailto:galloedilfast@virgilio.it"
                    >
                      galloedilfast@virgilio.it
                    </a>
                  </div>
                </li>
                <li
                  className="margin-top-7"
                  onClick={() => {
                    window.location.href = "/contatti"
                  }}
                >
                  <div className="inner">
                    <button>Richiedi preventivo online</button>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col span_1_of_3">
              <a
                href="https://www.iubenda.com/privacy-policy/92900835"
                className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                title="Privacy Policy "
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
