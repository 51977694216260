import React from "react"
import { BrowserRouter } from "react-router-dom"
import { CookieBanner } from "@palmabit/react-cookie-law"

import "./App.css"
import { Footer } from "./components/layouts/layout"
import Routes from "./Routes"
function App() {
  return (
    <BrowserRouter>
      <CookieBanner
        message="Questo sito fa uso di cookie per migliorare l’esperienza di navigazione degli utenti e per raccogliere informazioni sull’utilizzo del sito stesso. Può conoscere i dettagli consultando la nostra privacy policy qui. Proseguendo nella navigazione si accetta l’uso dei cookie; in caso contrario è possibile abbandonare il sito."
        wholeDomain
        policyLink="https://www.iubenda.com/privacy-policy/92900835"
        onAccept={() => {}}
        onAcceptPreferences={() => {}}
        onAcceptStatistics={() => {}}
        onAcceptMarketing={() => {}}
        acceptButtonText={"Accetto"}
        declineButtonText={"Non accetto"}
      />
      <Routes />
      <Footer />
    </BrowserRouter>
  )
}

export default App
