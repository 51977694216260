import { slugify } from "../../util"
import cities from "../../assets/list_cities.json"

import React, { Suspense } from "react"

import Home from "../../components/pages/Home"

import AboutUs from "../../components/pages/AboutUs"

const Services = React.lazy(() => import("../../components/pages/Services"))
const ProjectFullWidth = React.lazy(() =>
  import("../../components/pages/ProjectFullWidth")
)
const Contact = React.lazy(() => import("../../components/pages/Contact"))
const Bath = React.lazy(() => import("../../components/pages/Bath"))

const routes = [
  {
    path: "/",
    component: (props) => (
      <Suspense fallback={<div className="loading-loader" />}>
        <Home {...props} />
      </Suspense>
    ),
  },
  {
    path: "/su-di-noi",
    component: (props) => (
      <Suspense fallback={<div className="loading-loader" />}>
        <AboutUs {...props} />
      </Suspense>
    ),
  },
  {
    path: "/i-nostri-servizi",
    component: (props) => (
      <Suspense fallback={<div className="loading-loader" />}>
        {" "}
        <Services {...props} />{" "}
      </Suspense>
    ),
  },
  {
    path: "/i-nostri-lavori",
    component: (props) => (
      <Suspense fallback={<div className="loading-loader" />}>
        <ProjectFullWidth {...props} />
      </Suspense>
    ),
  },
  {
    path: "/contatti",
    component: (props) => (
      <Suspense fallback={<div className="loading-loader" />}>
        <Contact {...props} />{" "}
      </Suspense>
    ),
  },
  cities.map((city) => ({
    path: `/ristrutturazione-bagno-${slugify(city.FIELD2)}`,
    component: (props) => (
      <Suspense fallback={<div className="loading-loader" />}>
        <Bath
          {...props}
          city={city.FIELD2}
          helmetTitle={`Ristrutturazione bagno ${city.FIELD2}`}
          helmetDescription={`VGA Edil fast impresa edile milano lombardia dal 2001 , ristrutturare il tuo bagno a ${city.FIELD2}`}
          helmetTags={`ristrutturazione,rifacimento,bagno,ristrutturazione,Ristruttuzione bagno a ${city.FIELD2}`}
          canonical={`https://galloedilfast.it/ristrutturazione-bagno-${slugify(
            city.FIELD2
          )}`}
        />
      </Suspense>
    ),
  })),
  cities.map((city) => ({
    path: `/rifacimento-bagno-${slugify(city.FIELD2)}`,
    component: (props) => (
      <Suspense fallback={<div className="loading-loader" />}>
        {" "}
        <Bath
          {...props}
          city={city.FIELD2}
          helmetTitle={`Rifacimento bagno ${city.FIELD2}`}
          helmetDescription={`VGA Edil fast impresa edile milano lombardia dal 2001 , Rifacimento bagno a ${city.FIELD2}`}
          helmetTags={`ristrutturazione,rifacimento,bagno,ristrutturazione,rifacimento bagno a ${city.FIELD2}`}
          canonical={`https://galloedilfast.it/rifacimento-bagno-${slugify(
            city.FIELD2
          )}`}
        />
      </Suspense>
    ),
  })),
  /*
    { path: '/services-detail', component: ServiceDetail},
    { path: '/project-detail', component: ProjectDetail},
     */
].flat()

export default routes
