import React from "react"
import { withRouter } from "react-router-dom"

const BottomBar = () => {
  return (
    <div id="bottom" className="clearfix has-spacer">
      <div id="bottom-bar-inner" className="container">
        <div className="bottom-bar-inner-wrap">
          <div className="bottom-bar-content">
            <div id="copyright">
              © <span className="text">EdilFast di Gallo Vincenzo </span>
            </div>
          </div>
          {/* /.bottom-bar-content */}
        </div>
      </div>
    </div>
  )
}

export default withRouter(BottomBar)
