import React from "react"

const Service = () => {
  const state = {
    slidecard: [
      {
        id: 1,
        srcimg: "assets/img/gallery/piastrellature_mosaici/appfinito.jpeg",
        heading: "Appartamento finito",
        meta: "Appartamento finito",
        links: "/house_finished",
        text: "",
      },
      {
        id: 2,
        srcimg: "assets/img/gallery/bagni/1.jpeg",
        heading: "Rifacimento bagni e cucine",
        links: "/baths",
        text: "",
      },
      {
        id: 3,
        srcimg: "assets/img/gallery/piastrellature_mosaici/2.webp",
        heading: "Piastrellature - Mosaici",
        meta: "Piastrellature - Mosaici",
        links: "/tiling",
        text: "",
      },
      {
        id: 4,
        srcimg: "assets/img/gallery/blindati/1.jpeg",
        heading: "Blindati",
        meta: "Blindati",
        links: "/armored",
        text: "",
      },
      {
        id: 8,
        srcimg: "assets/img/gallery/marmi_piastrelle/2.jpeg",
        heading: "Marmi e piastrelle",
        meta: "Marmi e piastrelle",
        links: "/marbles",
        text: "",
      },
      {
        id: 5,
        srcimg: "assets/img/gallery/pareti_cartongesso/1.jpeg",
        heading: "Pareti in cartongesso",
        links: "/plasterboard_walls",
        meta: "Pareti in cartongesso",
        text: "",
      },
      {
        id: 6,
        srcimg: "assets/img/gallery/imbiancatura/1.jpeg",
        heading: "Imbiancatura - Stucchi veneziani",
        meta: "Imbiancatura - Stucchi veneziani",
        links: "/whitewashing",
        text: "",
      },
      {
        id: 7,
        srcimg: "assets/img/gallery/velature/1.jpeg",
        heading: "Velature - Tamponanti",
        meta: "Velature - Tamponanti",
        links: "/glazing_buffers",
        text: "",
      },
    ],
  }

  return (
    <div
      className="themesflat-carousel-box data-effect clearfix"
      data-gap={30}
      data-column={3}
      data-column2={2}
      data-column3={1}
      data-auto="false"
    >
      <div className="owl-carousel owl-theme">
        {state.slidecard.map((data) => (
          <div
            className="themesflat-image-box style-1 has-icon icon-right w65 clearfix"
            key={data.id}
          >
            <div className="image-box-item">
              <div className="inner">
                <div className="thumb data-effect-item">
                  <img src={data.srcimg} alt="altimage" />
                  <div className="overlay-effect bg-color-accent" />
                </div>
                <div className="text-wrap">
                  <h5 className="heading">{data.heading}</h5>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Service
