import React from "react";
import {BrowserRouter,Route, Switch} from "react-router-dom";
import routes from "./components/pages";
function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                {
                    routes.map((data,idx) => (
                        <Route exact path={data.path} render={data.component} key={idx}/>
                    ))
                }
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;
