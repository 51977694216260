import React from "react"
import { Link } from "react-router-dom"

const Project = () => {
  const state = {
    data: [
      {
        id: 1,
        srcimg: "assets/img/gallery/piastrellature_mosaici/appfinito.jpeg",
        heading: "Appartamento finito",
        title: "Appartamento finito",
        links: "/i-nostri-lavori?type=house_finished",
        text: "",
      },
      {
        id: 2,
        srcimg: "assets/img/gallery/bagni/1.jpeg",
        heading: "Rifacimento bagni e cucine",
        links: "/i-nostri-lavori?type=tiling",
        text: "",
      },
      {
        id: 3,
        srcimg: "assets/img/gallery/piastrellature_mosaici/2.webp",
        heading: "Piastrellature - Mosaici",
        title: "Piastrellature - Mosaici",
        links: "/i-nostri-lavori?type=marbles",
        text: "",
      },
      {
        id: 4,
        srcimg: "assets/img/gallery/marmi_piastrelle/2.jpeg",
        heading: "Marmi e piastrelle",
        title: "Marmi e piastrelle",
        links: "/i-nostri-lavori?type=armored",
        text: "",
      },
      {
        id: 5,
        srcimg: "assets/img/gallery/pareti_cartongesso/1.jpeg",
        heading: "Pareti in cartongesso",
        links: "/i-nostri-lavori?type=plasterboard_walls",
        title: "Pareti in cartongesso",
        text: "",
      },
      {
        id: 6,
        srcimg: "assets/img/gallery/imbiancatura/1.jpeg",
        heading: "Imbiancatura - Stucchi veneziani",
        title: "Imbiancatura - Stucchi veneziani",
        links: "/i-nostri-lavori?type=whitewashing",
        text: "",
      },
      {
        id: 7,
        srcimg: "assets/img/gallery/velature/1.jpeg",
        heading: "Velature - Tamponanti",
        title: "Velature - Tamponanti",
        links: "/i-nostri-lavori?type=glazing_buffers",
        text: "",
      },
    ],
  }

  return (
    <div
      className="themesflat-carousel-box clearfix"
      data-gap={30}
      data-column={4}
      data-column2={2}
      data-column3={1}
      data-auto="false"
    >
      <div className="owl-carousel owl-theme">
        {state.data.map((data) => (
          <div
            className="themesflat-project style-1 data-effect  clearfix"
            key={data.id}
          >
            <div className="project-item">
              <div className="inner">
                <div className="thumb data-effect-item has-effect-icon w40 offset-v-43 offset-h-46">
                  <img
                    src={data.srcimg}
                    alt="altimage"
                    style={{ maxHeight: "590px" }}
                  />
                  <div className="text-wrap text-center">
                    <h5 className="heading">
                      <Link to="#">{data.heading}</Link>
                    </h5>
                    <div className="elm-title">
                      <span>
                        <Link
                          onClick={() => {
                            window.location.href = data.links
                          }}
                          to={data.links}
                        >
                          {data.title}
                        </Link>
                      </span>
                      <span>
                        <Link
                          onClick={() => {
                            window.location.href = data.links
                          }}
                          to={data.links}
                        >
                          {data.text}
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="elm-link">
                    <Link
                      onClick={() => {
                        window.location.href = data.links
                      }}
                      to={data.links}
                      className="icon-1 icon-search"
                    />
                  </div>
                  <div className="overlay-effect bg-color-3" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Project
