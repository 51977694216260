import React from "react"
import { Link } from "react-router-dom"

const ServicesBox = () => {
  const state = {
    cardbox: [
      {
        id: 1,
        colspan: [
          {
            idx: 1,
            icon: "autora-icon-build",
            title: "Rifacimento bagni e cucine",
            text: "",
          },
        ],
        colleft: [
          {
            idx: 1,
            icon: "autora-icon-hat-outline",
            title: "Piastrellature - Mosaici",
            text: "",
          },
        ],
      },
      {
        id: 2,
        colspan: [
          {
            idx: 1,
            icon: "autora-icon-author-outline",
            title: "Marmi e piastrelle",
            text: "",
          },
        ],
        colleft: [
          {
            idx: 1,
            icon: "autora-icon-build-home",
            title: "Pareti in cartongesso",
            text: "",
          },
        ],
      },
      {
        id: 3,
        colspan: [
          {
            idx: 1,
            icon: "autora-icon-building-outline",
            title: "Imbiancatura - Stucchi veneziani",
            text: "",
          },
        ],
        colleft: [
          {
            idx: 1,
            icon: "autora-icon-hat-outline",
            title: "Velature - Tamponanti",
            text: "",
          },
        ],
      },
    ],
  }

  return (
    <div className="themesflat-row gutter-30 clearfix">
      {state.cardbox.map((data) => (
        <div className="col span_1_of_4" key={data.id}>
          <div
            className="themesflat-spacer clearfix"
            data-desktop={0}
            data-mobile={35}
            data-smobile={35}
          />
          {data.colspan.map((spandata) => (
            <div
              className="themesflat-icon-box icon-left accent-color style-2 clearfix"
              key={spandata.idx}
            >
              <div className="icon-wrap">
                <i className={spandata.icon} />
              </div>
              <div className="text-wrap">
                <h5 className="heading">
                  <Link to="#">{spandata.title}</Link>
                </h5>
                <p className="sub-heading">{spandata.text}</p>
              </div>
            </div>
          ))}
          <div
            className="themesflat-spacer clearfix"
            data-desktop={62}
            data-mobile={35}
            data-smobile={35}
          />
          {data.colleft.map((leftcol) => (
            <div
              className="themesflat-icon-box icon-left accent-color style-2 clearfix"
              key={leftcol.idx}
            >
              <div className="icon-wrap font-size-35 line-height-50">
                <i className={leftcol.icon} />
              </div>
              <div className="text-wrap">
                <h5 className="heading">
                  <Link to="#">{leftcol.title}</Link>
                </h5>
                <p className="sub-heading margin-top-18">{leftcol.text}</p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default ServicesBox
