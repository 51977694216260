import React from "react"
import { Link } from "react-router-dom"

const IconBox = () => {
  const state = {
    iconbox: [
      {
        id: 1,
        icon: "autora-icon-quality",
        title: "Qualità assicurata",
        text: "Le nostre forniture edili sono tra le migliori in lombardia",
      },
      {
        id: 2,
        icon: "autora-icon-time",
        title: "Risparmia soldi e tempo con EdilFast",
        text: "Il nostro servizio è efficiente e a Buon mercato",
      },
      {
        id: 3,
        icon: "autora-icon-author",
        title: "La nostra esperienza",
        text: "Siamo operativi da più di 30 anni nel nostro campo, affidati a EdilFast non te ne pentirai.",
      },
    ],
  }

  return (
    <div className="row">
      {state.iconbox.map((data) => (
        <div className="col-md-4" key={data.id}>
          <div
            className="themesflat-spacer clearfix"
            data-desktop={0}
            data-mobile={0}
            data-smobile={35}
          />
          <div
            className="themesflat-content-box clearfix"
            data-margin="0 5px 0 5px"
            data-mobilemargin="0 0 0 0"
          >
            <div className="themesflat-icon-box icon-top align-center has-width w95 circle light-bg accent-color style-1 clearfix">
              <div className="icon-wrap">
                <i className={data.icon} />
              </div>
              <div className="text-wrap">
                <h5 className="heading">
                  <Link to="#">{data.title}</Link>
                </h5>
                <div className="sep clearfix" />
                <p className="sub-heading">{data.text}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default IconBox
