import React from "react"

const Faq = () => {
  const state = {
    accordion: [
      {
        id: 1,
        classname: "accordion-item active",
        heading: "Come posso ottenere un preventivo?",
        text: "Inviaci una mail a info@galloedilfast.it oppure un messaggio a +393288362365 altrimenti compila il form nella pagina contatti.",
      },
      {
        id: 2,
        classname: "accordion-item",
        heading: "Come funziona?",
        text: "Facci la tua richiesta ti invieremo un preventivo e saremo lieti di aiutarvi per il vostro lavoro",
      },
      {
        id: 3,
        classname: "accordion-item",
        heading: "Sei pronto?",
        text: "Una volta che verrà accettato il preventivo , consegnaci le tue chiavi EdilFast penserà al resto",
      },
    ],
  }

  return (
    <div className="col-md-6">
      <div
        className="themesflat-spacer clearfix"
        data-desktop={0}
        data-mobile={0}
        data-smobile={35}
      />
      <div
        className="themesflat-content-box"
        data-margin="0 0 0 18px"
        data-mobilemargin="0 0 0 0"
      >
        <div className="themesflat-headings style-1 clearfix">
          <h2 className="heading">FAQs</h2>
          <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
        </div>
        <div
          className="themesflat-spacer clearfix"
          data-desktop={38}
          data-mobile={35}
          data-smobile={35}
        />
        <div className="themesflat-accordions style-1 has-icon icon-left iconstyle-1 clearfix">
          {state.accordion.map((data) => (
            <div className={data.classname} key={data.id}>
              <h3 className="accordion-heading">
                <span className="inner">{data.heading}</span>
              </h3>
              <div className="accordion-content">
                <div>{data.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Faq
