const menus = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Chi siamo",
    link: "/su-di-noi",
  },
  {
    id: 3,
    name: "Alcuni ultimi lavori",
    link: "/i-nostri-lavori",
    namesub: [
      {
        id: 1,
        sub: "Rifacimento bagni e cucine",
        links: "/i-nostri-lavori?type=baths",
      },
      {
        id: 2,
        sub: "Piastrellature - Mosaici",
        links: "/i-nostri-lavori?type=tiling",
      },
      {
        id: 3,
        sub: "Marmi e piastrelle",
        links: "/i-nostri-lavori?type=marbles",
      },
      {
        id: 4,
        sub: "Blindati",
        links: "/i-nostri-lavori?type=armored",
      },
      {
        id: 5,
        sub: "Pareti in cartongesso",
        links: "/i-nostri-lavori?type=plasterboard_walls",
      },
      {
        id: 6,
        sub: "Imbiancatura - Stucchi veneziani",
        links: "/i-nostri-lavori?type=whitewashing",
      },
      {
        id: 7,
        sub: "Velature - Tamponanti",
        links: "/i-nostri-lavori?type=glazing_buffers",
      },
    ],
  },
  {
    id: 4,
    name: "Servizi",
    link: "/i-nostri-servizi",
    namesub: [
      {
        id: 8,
        sub: "Rifacimento bagni e cucine",
        links: "/baths",
      },
      {
        id: 9,
        sub: "Piastrellature - Mosaici",
        links: "/tiling",
      },
      {
        id: 10,
        sub: "Marmi e piastrelle",
        links: "/marbles",
      },
      {
        id: 11,
        sub: "Blindati",
        links: "/armored",
      },
      {
        id: 12,
        sub: "Pareti in cartongesso",
        links: "/plasterboard_walls",
      },
      {
        id: 13,
        sub: "Imbiancatura - Stucchi veneziani",
        links: "/whitewashing",
      },
      {
        id: 14,
        sub: "Velature - Tamponanti",
        links: "/glazing_buffers",
      },
    ],
    subhidden: 1,
  },
  {
    id: 5,
    name: "Contattaci",
    link: "/contatti",
  },
]

export default menus
