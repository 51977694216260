import React from "react"
import { Link } from "react-router-dom"
import {
  About,
  Faq,
  IconBox,
  Overview,
  Project,
  Service,
  ServicesBox,
  TitleHeading,
} from "../layouts/home01/index"
import { BottomBar, Header, TopBar } from "../layouts/layout/index"
import ReactWhatsapp from "react-whatsapp"
import { FaWhatsapp } from "react-icons/fa"
import { Helmet } from "react-helmet"
import bannerhome1 from "../layouts/home01/data_banner"

const Home = () => {
  const state = {
    headers: [
      {
        id: 1,
        logoweb: "assets/img/logo-small.png",
        names: "Home",
      },
    ],
    headingchoose: [
      {
        id: 1,
        classnames: "heading",
        title: "La scelta migliore per te e la tua casa",
        text: "Abbiamo completato migliaia di lavori - Con più di 30 anni di esperienza nel campo, siamo i più adatti per i lavori di ristrutturazione e design.",
        classtext: "sub-heading",
      },
    ],
    headingservice: [
      {
        id: 1,
        classnames: "heading",
        title: "Impresa edile",
        text: "Siamo esperti in : Appartamenti finiti , Rifacimento bagni e cucine , Piastrellature - Mosaici, Marmi e piastrelle, Pareti in cartongesso, Imbiancatura - Stucchi veneziani , Velature - Tamponanti",
        classtext: "sub-heading",
      },
    ],
    headingproject: [
      {
        id: 1,
        classnames: "heading text-white",
        title: "Esperienza e Costanza",
        classtext: "sub-heading",
      },
    ],
    servicebox: [
      {
        id: 1,
        classnames: "heading font-size-30",
        title: "I nostri migliori servizi",
        classtext: "sub-heading font-weight-400",
        text: "Sei interessato a EdilFast ? Contattaci non aspettare",
      },
    ],
  }

  return (
    <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      <Helmet>
        <title>VGA Edil fast - Impresa Edile Milano - Ristrutturazioni Milano</title>
        <link rel="canonical" href="https://galloedilfast.it/" />
        <meta
          name="description"
          content="Impresa edile milano dal 2001, ristrutturazione bagno, ristrutturazione cucina, ristrutturazione interni, imbiancature."
        />
        <meta
          name="keywords"
          content="ristrutturazione,ristrutturazione bagni,piastrellature,marmi,velature,idraulici,imbiancature"
        />
      </Helmet>
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            <TopBar />
            {state.headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="rev_slider_wrapper fullwidthbanner-container">
                      <div id="rev-slider1" className="rev_slider fullwidthabanner">
                        <ul>
                          {bannerhome1.map((data) => (
                            <li data-transition="random" key={data.id}>
                              <img
                                src={data.srcimg}
                                alt="altimage"
                                style={{ opacity: 0.6 }}
                                data-bgposition="center center"
                                data-no-retina
                              />
                              <div
                                style={{ color: "red !important" }}
                                className={data.classnames}
                                data-x={data.datax}
                                data-hoffset={data.datahset}
                                data-y="['middle','middle','middle','middle']"
                                data-voffset={data.datavset1}
                                data-fontsize="['52','52','42','32']"
                                data-lineheight="['70','70','40','24']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                data-mask_in="x:0px;y:[100%];"
                                data-mask_out="x:inherit;y:inherit;"
                                data-start={700}
                                data-splitin="none"
                                data-splitout="none"
                                data-responsive_offset="on"
                              >
                                Edil fast Dal 2001
                              </div>
                              <div
                                style={{ color: "red !important" }}
                                className={data.classtext}
                                data-x={data.datax}
                                data-hoffset={data.datahset}
                                data-y="['middle','middle','middle','middle']"
                                data-voffset={data.datavset3}
                                data-fontsize="['52','52','42','32']"
                                data-lineheight="['65','65','45','35']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                data-mask_in="x:0px;y:[100%];"
                                data-mask_out="x:inherit;y:inherit;"
                                data-start={1000}
                                data-splitin="none"
                                data-splitout="none"
                                data-responsive_offset="on"
                              >
                                Preventivi gratuiti senza impegno
                              </div>
                              <div
                                className={data.classbtn}
                                data-x={data.datax}
                                data-hoffset={data.datahset}
                                data-y="['middle','middle','middle','middle']"
                                data-voffset={data.datavset}
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                data-mask_in="x:0px;y:[100%];"
                                data-mask_out="x:inherit;y:inherit;"
                                data-start={1000}
                                data-splitin="none"
                                data-splitout="none"
                                data-responsive_offset="on"
                              >
                                <Link
                                  onClick={() => {
                                    window.location.href = "/contatti"
                                  }}
                                  to="/contact"
                                  className="themesflat-button bg-accent big"
                                >
                                  <span>Ottieni ora il tuo preventivo</span>
                                </Link>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="row-iconbox">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={60}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            {state.headingchoose.map((data) => (
                              <TitleHeading data={data} key={data.id} />
                            ))}
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={42}
                              data-mobile={35}
                              data-smobile={35}
                            />
                          </div>
                        </div>

                        <IconBox />
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={41}
                              data-mobile={35}
                              data-smobile={35}
                            />
                            <div className="elm-button text-center">
                              <Link
                                to="#"
                                onClick={() => {
                                  window.location.href = "/contatti"
                                }}
                                className="themesflat-button bg-accent"
                              >
                                Richiedi un preventivo
                              </Link>
                            </div>
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={73}
                              data-mobile={60}
                              data-smobile={60}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <About />
                    <div className="row-services">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={60}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            {state.headingservice.map((data) => (
                              <TitleHeading data={data} key={data.id} />
                            ))}
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={39}
                              data-mobile={35}
                              data-smobile={35}
                            />
                            <Service />
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={50}
                              data-mobile={35}
                              data-smobile={35}
                            />
                            <div className="elm-button text-center">
                              <Link
                                to="#"
                                onClick={() => {
                                  window.location.href = "/i-nostri-servizi"
                                }}
                                className="themesflat-button bg-accent"
                              >
                                I nostri servizi
                              </Link>
                            </div>
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={73}
                              data-mobile={60}
                              data-smobile={60}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row-quote bg-row-1">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={40}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            <div className="themesflat-quote clearfix">
                              <div className="quote-item">
                                <div className="inner">
                                  <div className="heading-wrap">
                                    <h3 className="heading">
                                      Inizia il tuo lavoro ora con EdilFast
                                    </h3>
                                  </div>
                                  <div className="button-wrap has-icon icon-left">
                                    <Link
                                      to="#"
                                      className="themesflat-button  small"
                                    >
                                      <ReactWhatsapp
                                        number="+393288362365"
                                        message=""
                                      >
                                        <FaWhatsapp />
                                      </ReactWhatsapp>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={31}
                              data-mobile={60}
                              data-smobile={60}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row-iconbox">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={61}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            {state.servicebox.map((data) => (
                              <TitleHeading data={data} key={data.id} whatsapp={1} />
                            ))}
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={57}
                              data-mobile={35}
                              data-smobile={35}
                            />
                            <ServicesBox />
                            <div className="row">
                              <div className="col-md-12">
                                <div
                                  className="themesflat-spacer clearfix"
                                  data-desktop={72}
                                  data-mobile={60}
                                  data-smobile={60}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row-project parallax parallax-1 parallax-overlay">
                      <div className="project-overlay">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="themesflat-spacer clearfix"
                                data-desktop={60}
                                data-mobile={60}
                                data-smobile={60}
                              />
                              {state.headingproject.map((data) => (
                                <TitleHeading data={data} key={data.id} />
                              ))}
                              <div
                                className="themesflat-spacer clearfix"
                                data-desktop={30}
                                data-mobile={35}
                                data-smobile={35}
                              />

                              <Project />
                              <div
                                className="themesflat-spacer clearfix"
                                data-desktop={41}
                                data-mobile={35}
                                data-smobile={35}
                              />
                              <div className="elm-button text-center">
                                <Link
                                  to="#"
                                  onClick={() => {
                                    window.location.href = "/i-nostri-lavori"
                                  }}
                                  className="themesflat-button bg-accent"
                                >
                                  Esplora alcuni ultimi lavori{" "}
                                </Link>
                              </div>
                              <div
                                className="themesflat-spacer clearfix"
                                data-desktop={73}
                                data-mobile={60}
                                data-smobile={60}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row-tabs">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={61}
                              data-mobile={60}
                              data-smobile={60}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <Overview />
                          <Faq />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="themesflat-spacer clearfix"
                          data-desktop={72}
                          data-mobile={60}
                          data-smobile={60}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {state.headers.map((data, idx) => (
            <BottomBar data={data} key={idx} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Home
