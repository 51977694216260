import React from "react"

const TopBar = () => {
  return (
    <div id="top-bar">
      <div id="top-bar-inner" className="container">
        <div className="top-bar-inner-wrap">
          <div className="top-bar-content">
            <div className="inner">
              <address className="address content">Via bolivian 20, Pioltello (mi)</address>
              <span className="phone content">+39 3288362365</span>
              <span className="time content">Lunedi-Venerdi: 8am - 6pm</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar
