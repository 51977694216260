import React from "react"
import { Link, withRouter } from "react-router-dom"
import menus from "../menu"
import Headroom from "react-headroom"

const Header = ({ location, data }) => {
  return (
    <Headroom>
      <header id="site-header">
        <div id="site-header-inner" className="container">
          <div className="wrap-inner clearfix">
            <div id="site-logo" className="clearfix">
              <div id="site-log-inner">
                <Link
                  to="/"
                  onClick={() => {
                    window.location.href = "/"
                  }}
                  rel="home"
                  className="main-logo"
                >
                  <img
                    src={window.location.origin + "/assets/img/logo-small.png"}
                    alt="EdilFast"
                    width={130}
                    height={39}
                    data-retina={data.logoweb}
                    data-width={130}
                    data-height={39}
                  />
                </Link>
              </div>
            </div>
            <div className="mobile-button">
              <span />
            </div>
            <nav id="main-nav" className="main-nav">
              <ul id="menu-primary-menu" className="menu">
                {menus.map((data) => (
                  <li
                    className={
                      data.name === data.names
                        ? "menu-item menu-item-has-children current-menu-item"
                        : "menu-item menu-item-has-children"
                    }
                    key={data.id}
                  >
                    <Link
                      to={"/" + data.link}
                      onClick={() => {
                        window.location.href = data.link
                      }}
                    >
                      {data.name}
                    </Link>
                    {data.namesub && !data.subhidden && (
                      <ul className="sub-menu">
                        {data.namesub.map((submenu) => (
                          <li
                            className={
                              location.pathname === submenu.links
                                ? "menu-item current-item"
                                : "menu-item"
                            }
                            key={submenu.id}
                          >
                            <Link
                              to={submenu.links}
                              onClick={() => {
                                window.location.href = submenu.links
                              }}
                            >
                              {submenu.sub}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </Headroom>
  )
}

export default withRouter(Header)
