import React from "react"
import ReactWhatsapp from "react-whatsapp"
import { FaWhatsapp } from "react-icons/fa"
import { Link } from "react-router-dom"

const TitleHeading = ({ data, whatsapp }) => {
  return (
    <div className="themesflat-headings style-1 text-center clearfix">
      <h2 className={data.classnames}>{data.title}</h2>
      <div className="sep has-icon width-125 clearfix">
        <div className="sep-icon">
          <span className="sep-icon-before sep-center sep-solid" />
          <span className="icon-wrap">
            <i className="autora-icon-build" />
          </span>
          <span className="sep-icon-after sep-center sep-solid" />
        </div>
      </div>
      <p className={data.classtext}>{data.text}</p>
      {whatsapp && whatsapp === 1 && (
        <Link to="#" className="themesflat-button  small">
          <ReactWhatsapp number="+393288362365" message="">
            Scrivici su whatsapp
            <FaWhatsapp />
          </ReactWhatsapp>
        </Link>
      )}
    </div>
  )
}

export default TitleHeading
